import * as React from "react";

import {AutoSuggest, Button, SelectInput, TextInput, Toggle} from "@danfoss/etui-core";
import {Col, Form, Row} from "react-bootstrap";
import TranslationController from "../../../controllers/api/TranslationController";
import Services from "../../../controllers/utils/Services";
import {Company, CompanySuggestion} from "../../../models/company/companyModel";
import {CompanyType, CompanyTypeSuggestion} from "../../../models/company/companyTypeModel";
import {Country, CountrySuggestion} from "../../../models/country/countryModel";
import {Currency, CurrencySuggestion} from "../../../models/currency/currencyModel";
import {Locale, LocaleSuggestion} from "../../../models/locale/localeModel";
import {Timezone, TimezoneSuggestion} from "../../../models/timezone/timezoneModel";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";
import {ETUIValueToggleStateProps} from "../../../hooks/useETUIValueToggle";

interface FirstFormProps {
    code: string;
    selectedCompanyType: CompanyType;
    selectedCountryAlpha3Code: string;
    selectedCurrency: Currency;
    customerOrientedValue: boolean;
    selectedParentCompany: Company;
    customerOriented: boolean;
    companyName: string;
    pingDeviceValue: boolean;
    loading: boolean;
    timezoneName: string;
    localeName: string;
    vat: string;
    genVeboxCode: boolean;
    currencyName: string;
    companyType: string;
    country: string;
    parentCompanies: CompanySuggestion[];
    parentCompanyName: string;
    setParentCompanyName: (parentCompanyName: string) => void;
    setSelectedParentCompany: (company: Company) => void;
    currencies: CurrencySuggestion[];
    companyTypes: CompanyTypeSuggestion[];
    veboxCodeDisabled: boolean;
    countries: CountrySuggestion[];
    locales: LocaleSuggestion[];
    timezones: TimezoneSuggestion[];
    customerOrientedDisabled: boolean;
    currentStep: number;
    setCompanyName: (companyName: string) => void;
    setCurrencyName: (currencyName: string) => void;
    setSelectedCurrency: (selectedCurrency: Currency) => void;
    setCompanyType: (companyType: string) => void;
    setSelectedCompanyType: (selectedCompanyType: CompanyType) => void;
    setCode: (code: string) => void;
    setGenVeboxCode: (veboxCode: boolean) => void;
    setVat: (vat: string) => void;
    setCountry: (country: string) => void;
    setLocaleName: (localeName: string) => void;
    setSelectedLocale: (selectedLocale: Locale) => void;
    setTimezoneName: (timezoneName: string) => void;
    setSelectedTimezone: (selectedTimezone: Timezone) => void;
    setPingDeviceValue: (pingDeviceValue: boolean) => void;
    setCustomerOrientedValue: (customerOrientedValue: boolean) => void;
    setSelectedCountryAlpha3Code: (selectedCountryAlpha3Code: string) => void;
    setCurrentStep: (step: number) => void;
    currentCompany: Company;
    setCurrentCompany: (C: Company) => void;
    submit: () => void;
    selectedCountry: Country;
    setSelectedCountry: (country: Country) => void;
    companyLockUnlockReasonsToggleProps: ETUIValueToggleStateProps;
    companyIntegrationFlowToggleProps: ETUIValueToggleStateProps;
    companyMarketingToggleProps: ETUIValueToggleStateProps;
    companyGenericProductToggleProps: ETUIValueToggleStateProps;
    companyThermostatReadAndWriteToggleProps: ETUIValueToggleStateProps;
}

const InfoSection: React.FC<FirstFormProps> = (props) => {
    const services = new Services();
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.company_creation.info_section.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);

    const handleParentCompanyStateChange = (params, downshiftStateAndHelpers) => {
        if (!downshiftStateAndHelpers.isOpen) {
            props.setParentCompanyName("");
        } else if (params.hasOwnProperty("inputValue")) {
            props.setParentCompanyName(params.inputValue);
        }
    };

    function mandatoryFieldsCheck() {
        return (services.isEmpty(props.companyName) || services.isEmpty(props.companyType)
            || services.isEmpty(props.currencyName) || services.isEmpty(props.country)
            || services.isEmpty(props.timezoneName) || services.isEmpty(props.localeName)
        );
    }
    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <Form onSubmit={props.submit}>
                <Row className="w-100 m-0">
                    <GeneralAlert
                        message={infoMessage}
                    />
                </Row>
                <Row className={"mx-2 pb-3"}>
                    <Col>
                        <Form.Label>Parent Company</Form.Label>
                        <AutoSuggest
                            label="Insert Parent Company"
                            emptyPlaceholder="No Parent Company Found"
                            suggestions={props.parentCompanies}
                            inputValue={props.parentCompanyName}
                            // selectedItem={parentCompany}
                            onSelectedValueChange={services.onSelectedItemAndLabel(
                                props.setParentCompanyName,
                                props.setSelectedParentCompany)}
                            itemToString={services.itemToString}
                            onStateChange={handleParentCompanyStateChange}
                            isInputDisabled={props.currentCompany != null}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Company Name *</Form.Label>
                        <TextInput
                            name={"formName"}
                            label={"Insert Company Name*"}
                            type={"text"}
                            onChange={services.handleTextOnChange(props.setCompanyName)}
                            value={props.companyName}
                        />
                    </Col>
                </Row>
                <Row className={"mx-2 pb-3"}>
                    <Col>
                        <Form.Label>Code</Form.Label>
                        <TextInput
                            name={"formCode"}
                            label={"Insert Code"}
                            type={"text"}
                            value={props.code}
                            onChange={services.handleTextOnChange(props.setCode)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Generate vebox Code</Form.Label>
                        <br/>
                        <Toggle
                            name={"Vebox Code"}
                            isChecked={props.genVeboxCode}
                            onChange={services.handleCheckboxOnChange(props.setGenVeboxCode)}
                            renderLabels={services.handleCheckboxLabel()}
                            isDisabled={props.veboxCodeDisabled}
                        />
                    </Col>
                </Row>
                <Row className={"mx-2 pb-3"}>
                    <Col>
                        <Form.Label>Vat</Form.Label>
                        <TextInput
                            name={"formVat"}
                            label={"Insert Vat"}
                            type={"text"}
                            value={props.vat}
                            onChange={services.handleNumberOnChange(props.setVat)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Company Type *</Form.Label>
                        <>
                            <SelectInput
                                onChange={services.onSelectedItemAndLabel(props.setCompanyType, props.setSelectedCompanyType)}
                                name="Company Type"
                                label="Insert Company Type"
                                options={props.companyTypes.slice(1)}
                                searchable={true}
                                value={props.companyTypes.find((object) => object.label === props.companyType)}
                                defaultValue={props.companyTypes[0]}
                            />
                        </>
                    </Col>
                </Row>

                <Row className={"align-items-center h-100 mx-2 pb-3"}>
                    <Col>
                        <Form.Label>Currency *</Form.Label>
                        <>
                            <SelectInput
                                onChange={services.onSelectedItemAndLabel(props.setCurrencyName, props.setSelectedCurrency)}
                                name="Currency"
                                label="Insert Currency"
                                options={props.currencies.slice(1)}
                                searchable={true}
                                value={props.currencies.find((object) => object.label === props.currencyName)}
                                defaultValue={props.currencies[0]}
                            />
                        </>
                    </Col>
                    <Col>
                        <Form.Label>Country *</Form.Label>
                        <>
                            <SelectInput
                                onChange={services.onSelectedGuidAndItem(
                                    props.setCountry,
                                    props.setSelectedCountryAlpha3Code,
                                    props.setSelectedCountry,
                                )}
                                name="Country"
                                label="Insert Country"
                                options={props.countries.slice(1)}
                                searchable={true}
                                value={props.countries.find((object) => object.label === props.country)}
                                defaultValue={props.countries[0]}
                            />
                        </>
                    </Col>
                </Row>
                <Row className={"mx-2 pb-3"}>
                    <Col>
                        <Form.Label>Locale *</Form.Label>
                        <>
                            <SelectInput
                                onChange={services.onSelectedName(props.setLocaleName, props.setSelectedLocale)}
                                name="Locale"
                                label="Insert Locale"
                                options={props.locales.slice(1)}
                                searchable={true}
                                value={props.locales.find((object) => object.label === props.localeName)}
                                defaultValue={props.locales[0]}
                            />
                        </>
                    </Col>
                    <Col>
                        <Form.Label>Timezone *</Form.Label>
                        <>
                            <SelectInput
                                onChange={services.onSelectedName(props.setTimezoneName, props.setSelectedTimezone)}
                                name="Timezone"
                                label="Insert Timezone"
                                options={props.timezones.slice(1)}
                                searchable={true}
                                value={props.timezones.find((object) => object.label === props.timezoneName)}
                                defaultValue={props.timezones[0]}
                            />
                        </>
                    </Col>
                </Row>
                <Row className={"mx-2 pb-3"}>
                    <Col>
                        <Form.Label>Ping Device</Form.Label>
                        <br/>
                        <Toggle
                            name={"Ping Device"}
                            isChecked={props.pingDeviceValue}
                            onChange={services.handleCheckboxOnChange(props.setPingDeviceValue)}
                            renderLabels={services.handleCheckboxLabel()}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Customer Oriented</Form.Label>
                        <br/>
                        <Toggle
                            name={"Customer Oriented"}
                            isChecked={props.customerOrientedValue}
                            onChange={services.handleCheckboxOnChange(props.setCustomerOrientedValue)}
                            renderLabels={services.handleCheckboxLabel()}
                            isDisabled={props.customerOrientedDisabled}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Enable lock reasons</Form.Label>
                        <br/>
                        <Toggle
                            name={"companyLockUnlockReasons"}
                            {...props.companyLockUnlockReasonsToggleProps}
                            isDisabled={props.currentCompany != null}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Enable Integration Flow</Form.Label>
                        <br/>
                        <Toggle
                            name={"companyIntegrationFlow"}
                            {...props.companyIntegrationFlowToggleProps}
                            isDisabled={props.currentCompany != null}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Enable Marketing Configuration</Form.Label>
                        <br/>
                        <Toggle
                            name={"companyMarketingConfiguration"}
                            {...props.companyMarketingToggleProps}
                            isDisabled={props.currentCompany != null}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Assign generic product</Form.Label>
                        <br/>
                        <Toggle
                            name={"companyGenericProduct"}
                            {...props.companyGenericProductToggleProps}
                            isDisabled={props.currentCompany != null}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Enabled Thermostat Read & Write</Form.Label>
                        <br/>
                        <Toggle
                            name={"companyThermostatReadAndWrite"}
                            {...props.companyThermostatReadAndWriteToggleProps}
                            isDisabled={props.currentCompany != null}
                        />
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">
                    <Col className="my-auto">
                        <b><p style={{fontSize: 14}}>* mandatory fields</p></b>
                    </Col>
                    <Col className="my-auto">
                        <div className={"text-right"}>
                            <Button
                                onClick={props.submit}
                                variant="strong"
                                styles={{root: {mr: 10}}}
                                disabled={mandatoryFieldsCheck()}
                            >
                                {props.currentCompany == null ? " Create & next" : " Update & next"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            <LoadingModal loading={props.loading} />
        </>
    );
};

export default InfoSection;
