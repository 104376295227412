import {MultiSwitcher, Notification} from "@danfoss/etui-core";
import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {Card} from "react-bootstrap";
import {isValidPhoneNumber} from "react-phone-number-input";
import AlarmController from "../../controllers/api/AlarmController";
import CompanyController from "../../controllers/api/CompanyController";
import ComponentCategoryController from "../../controllers/api/ComponentCategoryController";
import ComponentModelController from "../../controllers/api/ComponentModelController";
import ComponentTypeController from "../../controllers/api/ComponentTypeController";
import CurrencyController from "../../controllers/api/CurrencyController";
import LocaleController from "../../controllers/api/LocaleController";
import SupportController from "../../controllers/api/SupportController";
import TimezoneController from "../../controllers/api/TimezoneController";
import Services from "../../controllers/utils/Services";
import {setLegacyBreadcrumb} from "../../index";
import {
    AlarmType,
    AlarmTypeSeverity,
    AlarmTypeSeveritySuggestions,
    CompanyAlarmTypeSuggestion,
    TableCompanyAlarmType,
} from "../../models/alarm/alarmType";
import {CompanyCreation} from "../../models/company/companyCreationModel";
import {Company, CompanySuggestion} from "../../models/company/companyModel";
import {CompanyType, CompanyTypeSuggestion} from "../../models/company/companyTypeModel";
import {PatchList} from "../../models/company/patchModel";
import {CompanyComponentModelCreation} from "../../models/component/companyComponentModel";
import {CompanyComponentTypeCreation} from "../../models/component/CompanyComponentTypeCreation";
import {ComponentBrand, ComponentBrandList, ComponentBrandSuggestion} from "../../models/component/componentBrand";
import {ComponentCategory, ComponentCategorySuggestion} from "../../models/component/componentCategory";
import {ComponentModel, TableComponentModel} from "../../models/component/componentModel";
import {ComponentCategoryType, ComponentType, TableComponentType} from "../../models/component/componentType";
import {Country, CountrySuggestion} from "../../models/country/countryModel";
import {Currency, CurrencySuggestion} from "../../models/currency/currencyModel";
import {Locale, LocaleSuggestion} from "../../models/locale/localeModel";
import {GeoPoint, GeoPosition} from "../../models/location/geopositionModel";
import {Location, LocationCreation} from "../../models/location/locationModel";
import {Suggestion} from "../../models/Suggestion";
import {Timezone, TimezoneSuggestion} from "../../models/timezone/timezoneModel";
import * as LegacyPage from "../../page";
import CompanyAlarmTypeSection from "./components/CompanyAlarmType";
import CompanyComponentModelsSection from "./components/CompanyComponentModelsSection";
import CompanyComponentTypeSection from "./components/CompanyComponentTypeSection";
import DefaultWarehouseSection from "./components/DefaultWarehouseSection";
import InfoSection from "./components/InfoSection";
import {
    assignGenericProductToCompany, assignGenericRecipeToCompany,
    createOrUpdateCompanyIntegrationFlow,
    createOrUpdateCompanyReasonsFor,
    lockUnlockReasons,
    STANDARD_INTEGRATION_FLOW_NAME
} from "../Companies";
import {useETUIValueToggle} from "../../hooks/useETUIValueToggle";
import {enableMarketingConfiguration} from "../Companies/MarketingConfiguration";
import {changePropertyValue, COMPANY_WITH_THERMOSTAT_READ_AND_WRITE} from "../Properties";

export const CreateCompany: React.FC = () => {
    // controllers
    const companyController: CompanyController = new CompanyController();
    const localeController: LocaleController = new LocaleController();
    const timezoneController: TimezoneController = new TimezoneController();
    const supportController: SupportController = new SupportController();
    const currencyController: CurrencyController = new CurrencyController();
    const componentCategoryController: ComponentCategoryController = new ComponentCategoryController();
    const componentModelController: ComponentModelController = new ComponentModelController();
    const componentTypeController: ComponentTypeController = new ComponentTypeController();
    const alarmTypesController: AlarmController = new AlarmController();
    const services = new Services();
    const [currentCompany, setCurrentCompany] = React.useState<Company>(undefined);

    // Country Hooks
    const [country, setCountry] = React.useState<string>("");
    const [selectedCountryAlpha3Code, setSelectedCountryAlpha3Code] = React.useState<string>("");
    const [countries, setCountries] = React.useState<CountrySuggestion[]>([]);
    const [infoSectionLoading, setInfoSectionLoading] = React.useState<boolean>(false);

    // Alarm Type
    const [tableAlarmTypes, setTableAlarmTypes] = React.useState<TableCompanyAlarmType[]>([]);
    const [selectedAlarmType, setSelectedAlarmType] = React.useState<AlarmType>(undefined);
    const [alarmTypes, setAlarmTypes] = React.useState<CompanyAlarmTypeSuggestion[]>([]);
    const [alarmTypesName, setAlarmTypesName] = React.useState<string>("");
    const [alarmTypeSectionLoading, setAlarmTypeSectionLoading] = React.useState<boolean>(false);

    // CompanyTpe Hooks
    const [selectedCompanyType, setSelectedCompanyType] = React.useState<CompanyType>(undefined);
    const [companyTypes, setCompanyTypes] = React.useState<CompanyTypeSuggestion[]>([]);
    const [companyType, setCompanyType] = React.useState<string>("");

    // Timezone Hooks
    const [selectedTimezone, setSelectedTimezone] = React.useState<Timezone>(undefined);
    const [timezones, setTimezones] = React.useState<TimezoneSuggestion[]>([]);
    const [timezoneName, setTimezoneName] = React.useState<string>("");

    // Locale Hooks
    const [selectedLocale, setSelectedLocale] = React.useState<Locale>(undefined);
    const [locales, setLocales] = React.useState<LocaleSuggestion[]>([]);
    const [localeName, setLocaleName] = React.useState<string>("");

    // ParentCompany Hooks
    const [selectedParentCompany, setSelectedParentCompany] = React.useState<Company>(undefined);
    const [parentCompanyName, setParentCompanyName] = React.useState<string>("");
    const [parentCompanies, setParentCompanies] = React.useState<CompanySuggestion[]>([]);

    // Currency Hooks
    const [selectedCurrency, setSelectedCurrency] = React.useState<Currency>(undefined);
    const [currencyName, setCurrencyName] = React.useState<string>("");
    const [currencies, setCurrencies] = React.useState<CurrencySuggestion[]>([]);

    // Text Input Hooks
    const [code, setCode] = React.useState<string>("");
    const [vat, setVat] = React.useState<string>("");
    // Disabled Input Hooks
    const [veboxCodeDisabled, setVeboxDisable] = React.useState<boolean>(false);
    const [customerOrientedDisabled, setCustomerOrientedDisabled] = React.useState<boolean>(false);

    // CheckBox Hooks
    const [pingDeviceValue, setPingDeviceValue] = React.useState<boolean>(false);
    const [customerOrientedValue, setCustomerOrientedValue] = React.useState<boolean>(false);
    const [genVeboxCode, setGenVeboxCode] = React.useState<boolean>(false);
    /* Component Model hooks */
    const [tableComponentModels, setTableComponentModels] = React.useState<TableComponentModel[]>([]);
    const [componentBrands, setComponentBrands] = React.useState<ComponentBrand[]>([]);
    const [componentModelSectionLoading, setComponentModelSectionLoading] = React.useState<boolean>(false);
    const [componentBrandSuggestions, setComponentBrandSuggestions] = React.useState<ComponentBrandSuggestion[]>([]);

    const [severities, setSeverities] = React.useState<AlarmTypeSeverity[]>([]);
    const [severitySuggestions, setSeveritySuggestions] = React.useState<AlarmTypeSeveritySuggestions[]>([]);

    /* Component Model Type hooks */
    const [tableComponentType, setTableComponentType] = React.useState<TableComponentType[]>([]);
    // const [componentTypeSectionLoading, setComponentTypeSectionLoading] = React.useState<boolean>(false);

    /* Component Category hooks */
    const [componentCategories, setComponentCategories] = React.useState<ComponentCategorySuggestion[]>([]);
    const [selectedComponentCategory, setSelectedComponentCategory] = React.useState<ComponentCategory>(undefined);
    const [componentCategoriesName, setComponentCategoriesName] = React.useState<string>("");

    /* Component Type Hooks */
    const [selectedComponentType, setSelectedComponentType] = React.useState<ComponentType>(undefined);
    const [componentTypes, setComponentTypes] = React.useState<Array<Suggestion<ComponentType>>>([]);
    const [componentTypesName, setComponentTypesName] = React.useState<string>("");
    const [selectedComponentTypes, setSelectedComponentTypes] = React.useState<ComponentCategoryType[]>([]);
    const [componentTypeSectionLoading, setComponentTypeSectionLoading] = React.useState<boolean>(false);

    /* Input Component Model Hooks */
    const [selectedInputComponentModel, setSelectedInputComponentModel] = React.useState<ComponentModel>(undefined);
    const [inputComponentModels, setInputComponentModels] = React.useState<Array<Suggestion<ComponentModel>>>([]);
    const [selectedInputComponentModelsName, setSelectedInputComponentModelsName] = React.useState<string>("");

    /* Company Hooks */
    const [companyName, setCompanyName] = React.useState<string>("");

    /* Default warehouse*/
    const [cityInputValue, setCityInputValue] = React.useState<string>("");
    const [provinceInputValue, setProvinceInputValue] = React.useState<string>("");
    const [locationAddressInputValue, setLocationAddressInputValue] = React.useState<string>("");
    const [codeInputValue, setCodeInputValue] = React.useState<string>("W1");
    const [locationDescriptionInputValue, setLocationDescriptionInputValue] = React.useState<string>("Warehouse");
    const [locationPhoneNumberInputValue, setLocationPhoneNumberValue] = React.useState<string>("");
    const [locationPrefixPhoneNumberInputValue, setLocationPrefixPhoneNumberInputValue] = React.useState<string>("");
    const [locationStreetNumberInputValue, setLocationStreetNumberValue] = React.useState<string>("");
    const [defaultWarehouseLoading, setDefaultWarehouseLoading] = React.useState<boolean>(false);

    const [verifiedLocation, setVerifiedLocation] = React.useState<boolean>(false);
    // Country Hooks
    const [selectedCountry, setSelectedCountry] = React.useState<Country>(undefined);

    const [validPhoneNumber, setValidPhoneNumber] = React.useState<boolean>(false);
    // Map hooks
    const [position, setPosition] = React.useState<GeoPoint>(services.firstPoint);
    const [customPosition, setCustomPosition] = React.useState<GeoPoint>({lat: "", lng: ""});
    const [markerPosition, setMarkerPosition] = React.useState<GeoPoint>(services.firstPoint);

    /* Steps hooks */
    const [currentStep, setCurrentStep] = React.useState<number>(0);

    const {value: shouldSetLockUnlock, etuiToggleProps: companyLockUnlockReasonsToggleProps} = useETUIValueToggle(
        true,
        {
            valueIfUnchecked: false,
        },
    );

    const {value: shouldSetIntegrationFlow, etuiToggleProps: companyIntegrationFlowToggleProps} = useETUIValueToggle(
        true,
        {
            valueIfUnchecked: false,
        },
    );

    const {value: shouldSetMarketing, etuiToggleProps: companyMarketingToggleProps} = useETUIValueToggle(
        true,
        {
            valueIfUnchecked: false,
        },
    );

    const {value: shouldSetGenericProduct, etuiToggleProps: companyGenericProductToggleProps} = useETUIValueToggle(
        true,
        {
            valueIfUnchecked: false,
        }
    );

    const {value: shouldSetThermostatRW, etuiToggleProps: companyThermostatRWToggleProps} = useETUIValueToggle(
        true,
        {
            valueIfUnchecked: false,
        }
    );

    function setCurrentCompanyFromResponse(body) {
        const companyTypeModel: CompanyType = {
            guid: body.type.guid,
            name: body.type.name,
            nameKey: body.type.nameKey,
        };
        const company: Company = {
            companyCode: body.companyCode,
            country: body.country,
            currencyGuid: body.currencyGuid,
            customerOriented: body.customerOriented,
            dataOwnerGuid: body.dataOwnerGuid,
            guid: body.guid,
            name: body.name,
            pingDevices: body.pingDevices,
            type: companyTypeModel,
            vatCode: body.vatCode,
            veboxCode: body.veboxCode,
        };
        setCurrentCompany(company);
    }

    React.useEffect(() => {
        setSelectedComponentType(undefined);
        setComponentTypesName("");
        if (selectedComponentCategory !== undefined) {
            componentCategoryController.getCategoryTypes(selectedComponentCategory.guid).then(
                (response) => {
                    const componentTypeSuggestions: Array<Suggestion<ComponentType>> = [
                        {label: "", value: "", item: undefined},
                        ...response.list.map((item) => ({
                            item,
                            label: item.name,
                            value: item.guid,
                        }))];
                    setComponentTypes(componentTypeSuggestions);
                },
            );
        }

    }, [selectedComponentCategory]);

    React.useEffect(() => {
        setSelectedInputComponentModel(undefined);
        setSelectedInputComponentModelsName("");
        if (selectedComponentType !== undefined) {
            componentTypeController.getComponentModelsByTypeGuid(selectedComponentType.guid).then(
                (response) => {
                    const componentModelSuggestions: Array<Suggestion<ComponentModel>> = [{label: "", value: ""},
                        ...response.list.map((item) => ({
                            item,
                            label: item.name,
                            value: item.guid,
                        }))];
                    setInputComponentModels(componentModelSuggestions);
                },
            );
        }

    }, [selectedComponentType]);

    React.useEffect(() => {
        companyController.getAllCompaniesTypes().then(
            (response) => {
                const companyTypeSuggestions: CompanyTypeSuggestion[] = [{label: "", value: ""},
                    ...response.list.map((item) => ({
                        item,
                        label: item.name,
                        value: item.guid,
                    })),
                ];
                setCompanyTypes(
                    companyTypeSuggestions,
                );
            },
        );
        localeController.getAllLocales().then(
            (response) => {
                const localeSuggestions: LocaleSuggestion[] = [{label: "", value: ""},
                    ...response.list.map((item) => ({
                        item,
                        label: item.name,
                        value: item.name,
                    })),
                ];
                setLocales(localeSuggestions);
            },
        );
        timezoneController.getAllTimezones().then(
            (response) => {
                const timezoneSuggestions: TimezoneSuggestion[] = [{label: "", value: ""},
                    ...response.list.map((item) => ({
                        item,
                        label: item.name,
                        value: item.guid,
                    })),
                ];
                setTimezones(timezoneSuggestions);
            },
        );
        supportController.getAllCountries().then(
            (response) => {
                const countrySuggestion: CountrySuggestion[] = [{label: "", value: ""},
                    ...response.list.map((item) => ({
                        item,
                        label: item.name,
                        value: item.alpha3Code,
                    })),
                ];
                setCountries(countrySuggestion);
            },
        );
        currencyController.getAllCurrencies().then(
            (response) => {
                const currencySuggestions: CurrencySuggestion[] = [
                    {label: "", value: ""},
                    ...response.list.map((item) => ({
                        item,
                        label: item.name,
                        value: item.guid,
                    })),
                ];
                setCurrencies(currencySuggestions);
            },
        );
        componentCategoryController.getComponentCategories().then(
            (response) => {
                const componentCategorySuggestions: ComponentCategorySuggestion[] = [
                    {label: "", value: "", item: undefined},
                    ...response.list.map((item) => ({
                        item,
                        label: item.name,
                        value: item.guid,
                    }))];
                setComponentCategories(componentCategorySuggestions);
            },
        );
        componentModelController.findComponentBrands().then(
            (response) => {
                setComponentBrands((response.content as ComponentBrandList).list);
            },
        );
        alarmTypesController.findAlarmTypeSeverities().then(
            (response) => {
                setSeverities(response.list);
            },
        );
    }, []);

    React.useEffect(() => {
        setComponentBrandSuggestions(
            componentBrands.map(
                (item) => {
                    const suggestion: ComponentBrandSuggestion = {
                        item,
                        value: item.guid,
                        label: item.description,
                    };
                    return suggestion;
                },
            ),
        );
    }, [componentBrands]);

    React.useEffect(() => {
        setSeveritySuggestions(
            severities.map(
                (item) => {
                    const suggestion: AlarmTypeSeveritySuggestions = {
                        item,
                        value: item.guid,
                        label: item.name,
                    };
                    return suggestion;
                },
            ),
        );
    }, [severities]);

    React.useEffect(() => {
        setValidPhoneNumber(locationPhoneNumberInputValue.length > 0 ?
            isValidPhoneNumber(locationPhoneNumberInputValue) :
            true);
    }, [locationPhoneNumberInputValue]);

    React.useEffect(() => {
        if (parentCompanyName.length >= 1) {
            setCustomerOrientedValue(false);
            setGenVeboxCode(false);
            setCustomerOrientedDisabled(true);
            setVeboxDisable(true);
        } else {
            setCustomerOrientedDisabled(false);
            setVeboxDisable(false);
        }
        if (parentCompanyName.length >= 3) {
            companyController.getCompaniesByPatternAndRelationType(parentCompanyName, "PARENT_COMPANIES").then(
                (response) => {
                    let companySuggestion: CompanySuggestion[] = response.list
                        .map((item) => ({
                            item,
                            label: item.name,
                            value: item.guid,
                        }));
                    if (currentCompany && currentCompany.guid) {
                        companySuggestion = response.list
                            .filter((e) => e.guid !== currentCompany.guid);
                    }
                    setParentCompanies(companySuggestion);
                },
            );
        } else {
            setParentCompanies([]);
        }
    }, [parentCompanyName]);

    React.useEffect(() => {
        if (currentCompany != null && currentCompany.defaultWarehouse != null) {
            services.firstPoint.lng = position.lng;
            services.firstPoint.lat = position.lat;
        }
    }, [currentCompany]);

    function findSelectedTypes() {
        const selectedTypes: ComponentCategoryType[] = tableComponentModels
            .filter((e) => e.checked)
            .map((item) => (
                {
                    guid: item.item.typeGuid,
                    categoryGuid: item.item.categoryGuid,
                    category: item.item.category,
                    name: item.item.type,
                }))
            .filter((value, index, self) => self
                .map((e) => e.guid)
                .indexOf(value.guid) === index);
        setSelectedComponentTypes(selectedTypes);

        const tableComponentTypes: TableComponentType[] = selectedTypes
            .map((item) => {
                const randomColor = services.getDefaultRandomColor();
                const newItem: TableComponentType = {
                    item,
                    color: randomColor,
                    clientName: item.name,
                    clientNameKey: "",
                    media: "",
                    displayColorPicker: false,
                    selectedColor: services.hexToRgb(randomColor),
                };
                return newItem;
            });
        setTableComponentType(tableComponentTypes);
    }

    function findSelectedAlarmTypes() {
        companyController.getAlarmTypesByCompanyGuid(currentCompany.guid).then(
            (response) => {
                if (response.length > 0) {
                    const tableCompanyAlarmTypes: TableCompanyAlarmType[] = response.map((item) => {
                        const randomColor: string = services.getDefaultRandomColor();
                        const severitySelected: AlarmTypeSeveritySuggestions = {
                            item: item.severity,
                            value: item.severity.guid,
                            label: item.severity.name,
                        };
                        const newItem: TableCompanyAlarmType = {
                            item,
                            checked: true,
                            description: (item.description !== null &&
                                item.description.trim() !== "") ? item.description : undefined,
                            color: item.color !== null ? item.color : randomColor,
                            displayColorPicker: false,
                            selectedColor: services.hexToRgb(item.color !== null ? item.color : randomColor),
                            name: item.alarmType.name,
                            severity: severitySelected,
                        };
                        return newItem;
                    });
                    setTableAlarmTypes(tableCompanyAlarmTypes);
                }
            },
        );
    }

    function defaultWarehouseSubmit() {
        if (currentCompany != null && currentCompany.defaultWarehouse != null) {
            patchDefaultWarehouseSection();
        } else {
            postDefaultWarehouseSection();
        }
    }

    function postDefaultWarehouseSection() {
        const geoPosition: GeoPosition = {
            latitude: parseFloat(position.lat),
            longitude: parseFloat(position.lng),
            precision: 0, // TODO
        };

        const paylod: LocationCreation = {
            address: locationAddressInputValue,
            city: cityInputValue,
            code: codeInputValue,
            country,
            dataOwnerCompanyGuid: currentCompany.guid,
            description: locationDescriptionInputValue,
            geoPosition,
            glatitude: parseFloat(customPosition.lat),
            glongitude: parseFloat(customPosition.lng),
            locationTypeGuid: services.LOCATION_TYPE_GUID,
            phoneNumber: locationPhoneNumberInputValue.replace(locationPrefixPhoneNumberInputValue, "").trim(),
            phonePrefix: locationPrefixPhoneNumberInputValue,
            province: provinceInputValue,
            addressNumber: locationStreetNumberInputValue,
        };
        console.log(paylod);
        const locationModel = JSON.parse(JSON.stringify(paylod));
        setDefaultWarehouseLoading(true);
        companyController.defaultWarehouse(locationModel, currentCompany.guid).then(
            (response: { status: number, body: any }) => {
                setDefaultWarehouseLoading(false);
                if (response.status >= 400 && response.status <= 500) {
                    services.errorNotification(response.body.errorType, response.body.message);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification(
                        " ",
                        "Default Warehouse created",
                    );
                    currentCompany.defaultWarehouse = response.body as Location;
                    setCurrentCompany(currentCompany);
                    services.nextStep(setCurrentStep, currentStep);
                }
            });
    }

    function infoSectionSubmit() {
        if (currentCompany != null) {
            patchInfoSection();
        } else {
            postInfoSection();
        }
    }

    function postInfoSection() {
        const payload: CompanyCreation = {
            companyCode: code,
            typeGuid: selectedCompanyType === undefined ? "" : selectedCompanyType.guid,
            country: selectedCountryAlpha3Code,
            currencyGuid: selectedCurrency === undefined ? "" : selectedCurrency.guid,
            customerOriented: customerOrientedValue,
            dataOwnerGuid: selectedParentCompany == null ? null : selectedParentCompany.guid,
            name: companyName,
            pingDevices: pingDeviceValue,
            timezone: timezoneName,
            locale: localeName,
            vatCode: vat,
            genVeboxCode,
        };

        console.log(payload);
        const companyModel = JSON.parse(JSON.stringify(payload));
        setInfoSectionLoading(true);
        companyController.createCompany(companyModel).then(
            (response: { status: number, body: any }) => {
                setInfoSectionLoading(false);
                if (response.status >= 400 && response.status <= 500) {
                    services.errorNotification(" ", response.body.message);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification(
                        "Company created",
                        response.body.name + " created successfully",
                    );
                    const newCompany = response.body as Company;
                    setCurrentCompany(newCompany);
                    services.nextStep(setCurrentStep, currentStep);
                    if (shouldSetLockUnlock) {
                        Promise.all([
                            createOrUpdateCompanyReasonsFor("lock", newCompany, lockUnlockReasons.lock),
                            createOrUpdateCompanyReasonsFor("unlock", newCompany, lockUnlockReasons.unlock)
                        ]).catch((reason) => {
                            console.error(reason);
                        });
                    }
                    if (shouldSetIntegrationFlow) {
                        createOrUpdateCompanyIntegrationFlow(newCompany, {
                            locale: localeName,
                            timezone: timezoneName,
                            flowName: STANDARD_INTEGRATION_FLOW_NAME,
                        }).catch((reason) => {
                            console.error(reason);
                        });
                    }
                    if (shouldSetMarketing) {
                        enableMarketingConfiguration(newCompany)
                            .catch((reason) => {
                                console.error(reason);
                            });
                    }
                    if (shouldSetGenericProduct) {
                        Promise.all([
                            assignGenericRecipeToCompany(newCompany),
                            assignGenericProductToCompany(newCompany),
                        ]).catch((reason) => {
                            console.error(reason);
                        });
                    }
                    if (shouldSetThermostatRW) {
                        changePropertyValue({guid: COMPANY_WITH_THERMOSTAT_READ_AND_WRITE}, newCompany, "true")
                            .catch(console.error);
                    }
                }
            });
    }

    function patchDefaultWarehouseSection() {
        const payload: PatchList = {
            list: [
                services.patchOption("test", "locationGuid", currentCompany.defaultWarehouse.guid),
                services.patchOption("replace", "code", codeInputValue),
                services.patchOption("replace", "description", locationDescriptionInputValue),
            ],
        };
        services.replaceOrRemove(payload, currentCompany.defaultWarehouse.address.addressNumber,
            locationStreetNumberInputValue, "addressNumber");
        if (locationPhoneNumberInputValue.trim() === "") {
            payload.list.push(
                services.patchOption("remove", "phonePrefix", null),
                services.patchOption("remove", "phoneNumber", null));
        } else {
            payload.list.push(
                services.patchOption("replace", "phonePrefix", locationPrefixPhoneNumberInputValue),
                services.patchOption("replace", "phoneNumber", locationPhoneNumberInputValue.replace(
                    locationPrefixPhoneNumberInputValue, "").trim()));
        }

        services.replaceOrRemove(payload, currentCompany.defaultWarehouse.geoPosition.latitude, customPosition.lat,
            "gLatitude");
        services.replaceOrRemove(payload, currentCompany.defaultWarehouse.geoPosition.longitude, customPosition.lng,
            "gLongitude");

        services.addReplaceOpIfValueChanged(payload,
            currentCompany.defaultWarehouse.address.country, country, "country");
        services.addReplaceOpIfValueChanged(payload,
            currentCompany.defaultWarehouse.address.province, provinceInputValue, "province");
        services.addReplaceOpIfValueChanged(payload,
            currentCompany.defaultWarehouse.address.city, cityInputValue, "city");
        services.addReplaceOpIfValueChanged(payload,
            currentCompany.defaultWarehouse.address.fullAddress, locationAddressInputValue, "address");

        console.log(payload);
        const defaultWarehousePatchModel = JSON.parse(JSON.stringify(payload));
        setDefaultWarehouseLoading(true);
        companyController.patchDefaultWarehouse(defaultWarehousePatchModel, currentCompany.guid).then(
            (response: { status: number, body: any }) => {
                setDefaultWarehouseLoading(false);
                if (response.status >= 400 && response.status <= 500) {
                    services.errorNotification(" ", response.body.message);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification(
                        "Default Warehouse Updated",
                        " ",
                    );
                    currentCompany.defaultWarehouse = response.body as Location;
                    setCurrentCompany(currentCompany);
                    services.nextStep(setCurrentStep, currentStep);
                }
            });
    }

    function patchInfoSection() {
        const payload: PatchList = {
            list: [
                services.patchOption("replace", "name", companyName),
                services.patchOption("replace", "currencyGuid", selectedCurrency == null ? "" : selectedCurrency.guid),
                services.patchOption("replace", "typeGuid",
                    selectedCompanyType == null ? "" : selectedCompanyType.guid),
                services.patchOption("replace", "pingDevices", pingDeviceValue),
                services.patchOption("replace", "country", selectedCountryAlpha3Code),
            ],
        };
        if (currentCompany.dataOwnerGuid == null) {
            payload.list.push(services.patchOption("replace", "customerOriented", customerOrientedValue));
            if (currentCompany.veboxCode !== null && !genVeboxCode) {
                payload.list.push(services.patchOption("remove", "veboxCode"));
            } else if (genVeboxCode) {
                payload.list.push(services.patchOption("replace", "genVeboxCode", genVeboxCode));
            }
            payload.list.push(services.patchOption("replace", "timezone", timezoneName));
            payload.list.push(services.patchOption("replace", "locale", localeName));
        }
        services.replaceOrRemove(payload, currentCompany.companyCode, code, "code");
        services.replaceOrRemove(payload, currentCompany.vatCode, vat, "vat");

        const companyPatchModel = JSON.parse(JSON.stringify(payload));
        setInfoSectionLoading(true);
        companyController.patchCompany(companyPatchModel, currentCompany.guid).then(
            (response: { status: number, body: any }) => {
                setInfoSectionLoading(false);
                if (response.status >= 400 && response.status <= 500) {
                    services.errorNotification(" ", response.body.message);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification(
                        "Company Updated",
                        response.body.name + " updated successfully",
                    );
                    setCurrentCompany(response.body as Company);
                    services.nextStep(setCurrentStep, currentStep);
                }
            });
    }

    function companyComponentModelsSectionSubmit(imageList: File[]) {
        setComponentModelSectionLoading(true);
        const companiesCreation: CompanyComponentModelCreation[] =
            tableComponentModels
                .filter((e) => e.checked)
                .map((e) => {
                    const creation: CompanyComponentModelCreation = {
                        clientCode: e.clientCode,
                        clientName: e.clientName,
                        clientNameKey: e.clientNameKey,
                        companyGuid: currentCompany.guid,
                        nickname: e.nickname,
                        newMediaName: e.newMedia !== undefined ? e.newMedia.name : null,
                        existingMediaGuid: e.existingMedia !== undefined ? e.existingMedia.guid : null,
                        componentBrandGuid: e.brand !== undefined ? e.brand.item.guid : null,
                        componentModelGuid: e.item.guid,
                    };
                    return creation;
                });
        companyController.linkComponents(
            currentCompany,
            imageList,
            companiesCreation,
        ).then(
            (response) => {
                setComponentModelSectionLoading(false);
                if (response.status === 201) {
                    findSelectedTypes();
                    setCurrentStep(currentStep + 1);
                } else if (response.status === 409) {
                    // show 409: Conflict banner
                    services.errorNotification(
                        " ", response.content.message.split(":")[1],
                    );
                } else {
                    // show general server error banner
                    services.errorNotification(
                        "Error", "General server error!");
                }
            },
        );
    }

    function companyComponentTypeSectionSubmit() {
        setComponentTypeSectionLoading(true);
        const companiesTypeCreation: CompanyComponentTypeCreation[] =
            tableComponentType
                .map((e) => {
                    const creation: CompanyComponentTypeCreation = {
                        clientName: e.clientName,
                        componentTypeGuid: e.item.guid,
                        clientNameKey: e.clientNameKey,
                        companyGuid: currentCompany.guid,
                        color: e.color,
                    };
                    return creation;
                });
        companyController.linkComponentTypes(
            currentCompany,
            companiesTypeCreation,
        ).then(
            (response) => {
                setComponentTypeSectionLoading(false);
                if (response.status === 201) {
                    findSelectedAlarmTypes();
                    setCurrentStep(currentStep + 1);
                } else if (response.status === 409) {
                    // show 409: Conflict banner
                    services.errorNotification(
                        " ", response.content.message.split(":")[1],
                    );
                } else {
                    // show general server error banner
                    services.errorNotification(
                        "Error", "General server error!");
                }
            },
        );
    }

    function finishedConfiguration() {
        Notification.open({
            message: "Success",
            description:
                "Company configured! \n remember to configure, events and diagnostic types",
            duration: 100,
            type: "success",
            theme: defaultTheme,
            className: "mt-5",
        });
    }

    const companySection = (
        <InfoSection
            // Country Hooks
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            code={code}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            currentCompany={currentCompany}
            setCurrentCompany={setCurrentCompany}
            customerOriented={customerOrientedValue}
            selectedCompanyType={selectedCompanyType}
            selectedCountryAlpha3Code={selectedCountryAlpha3Code}
            companyName={companyName}
            customerOrientedValue={customerOrientedValue}
            localeName={localeName}
            pingDeviceValue={pingDeviceValue}
            selectedCurrency={selectedCurrency}
            selectedParentCompany={selectedParentCompany}
            timezoneName={timezoneName}
            vat={vat}
            loading={infoSectionLoading}
            companyType={companyType}
            companyTypes={companyTypes}
            countries={countries}
            country={country}
            currencies={currencies}
            currencyName={currencyName}
            customerOrientedDisabled={customerOrientedDisabled}
            locales={locales}
            parentCompanies={parentCompanies}
            parentCompanyName={parentCompanyName}
            setCode={setCode}
            setCompanyName={setCompanyName}
            setCompanyType={setCompanyType}
            setCountry={setCountry}
            setCurrencyName={setCurrencyName}
            setCustomerOrientedValue={setCustomerOrientedValue}
            setLocaleName={setLocaleName}
            setParentCompanyName={setParentCompanyName}
            setPingDeviceValue={setPingDeviceValue}
            setSelectedCompanyType={setSelectedCompanyType}
            setSelectedCountryAlpha3Code={setSelectedCountryAlpha3Code}
            setSelectedCurrency={setSelectedCurrency}
            setSelectedLocale={setSelectedLocale}
            setSelectedParentCompany={setSelectedParentCompany}
            setSelectedTimezone={setSelectedTimezone}
            setTimezoneName={setTimezoneName}
            setVat={setVat}
            timezones={timezones}
            veboxCodeDisabled={veboxCodeDisabled}
            genVeboxCode={genVeboxCode}
            setGenVeboxCode={setGenVeboxCode}
            submit={infoSectionSubmit}
            companyLockUnlockReasonsToggleProps={companyLockUnlockReasonsToggleProps}
            companyIntegrationFlowToggleProps={companyIntegrationFlowToggleProps}
            companyMarketingToggleProps={companyMarketingToggleProps}
            companyGenericProductToggleProps={companyGenericProductToggleProps}
            companyThermostatReadAndWriteToggleProps={companyThermostatRWToggleProps}
        />
    );

    const componentModelSection = (
        <CompanyComponentModelsSection
            setLoading={setComponentModelSectionLoading}
            currentCompany={currentCompany}
            tableComponentModels={tableComponentModels}
            setTableComponentModels={setTableComponentModels}
            componentBrands={componentBrands}
            componentBrandSuggestions={componentBrandSuggestions}
            loading={componentModelSectionLoading}

            componentCategories={componentCategories}
            selectedComponentCategory={selectedComponentCategory}
            setSelectedComponentCategory={setSelectedComponentCategory}
            componentCategoriesName={componentCategoriesName}
            setComponentCategoriesName={setComponentCategoriesName}

            componentTypes={componentTypes}
            selectedComponentType={selectedComponentType}
            setSelectedComponentType={setSelectedComponentType}
            componentTypesName={componentTypesName}
            setComponentTypesName={setComponentTypesName}
            selectedComponentTypes={selectedComponentTypes}
            setSelectedComponentTypes={setSelectedComponentTypes}

            inputComponentModels={inputComponentModels}
            selectedInputComponentModel={selectedInputComponentModel}
            setSelectedInputComponentModel={setSelectedInputComponentModel}
            selectedInputComponentModelsName={selectedInputComponentModelsName}
            setSelectedInputComponentModelsName={setSelectedInputComponentModelsName}
            changeStep={setCurrentStep}
            step={currentStep}
            submit={companyComponentModelsSectionSubmit}
        />
    );

    const componentTypesSection = (
        <CompanyComponentTypeSection
            setTableComponentType={setTableComponentType}
            selectedComponentTypes={selectedComponentTypes}
            setSelectedComponentTypes={setSelectedComponentTypes}
            currentCompany={currentCompany}
            tableComponentType={tableComponentType}
            changeStep={setCurrentStep}
            loading={componentTypeSectionLoading}
            step={currentStep}
            submit={companyComponentTypeSectionSubmit}
        />
    );

    const companyAlarmTypeSection = (
        <CompanyAlarmTypeSection
            severitySuggestions={severitySuggestions}
            alarmTypes={alarmTypes}
            setModalLoading={setAlarmTypeSectionLoading}
            selectedAlarmType={selectedAlarmType}
            setSelectedAlarmType={setSelectedAlarmType}
            alarmTypesName={alarmTypesName}
            setAlarmTypesName={setAlarmTypesName}
            currentCompany={currentCompany}
            tableAlarmTypes={tableAlarmTypes}
            setTableAlarmTypes={setTableAlarmTypes}
            changeStep={setCurrentStep}
            step={currentStep}
            submit={() => {
                const preparedList = tableAlarmTypes.filter((e) => e.checked).map((e) => {
                    return {
                        alarmTypeGuid: e.item.alarmType.guid,
                        color: e.color,
                        severityGuid: e.severity.item.guid,
                    };
                });
                return companyController.putAlarmTypes(currentCompany.guid, preparedList).then(
                    (response) => {
                        if (response.status === 201) {
                            finishedConfiguration();
                            return true;
                        } else {
                            services.errorNotification(
                                " ",
                                "An error occurred!",
                            );
                            return false;
                        }
                    },
                );
            }}
            loading={alarmTypeSectionLoading}
            setLoading={setAlarmTypeSectionLoading}
        />
    );

    const defaultWarehouseSection = (
        <DefaultWarehouseSection
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            currentCompany={currentCompany}
            setCurrentCompany={setCurrentCompany}
            validPhoneNumber={validPhoneNumber}
            setValidPhoneNumber={setValidPhoneNumber}
            cityInputValue={cityInputValue}
            codeInputValue={codeInputValue}
            countries={countries}
            loading={defaultWarehouseLoading}
            setLoading={setDefaultWarehouseLoading}
            country={country}
            customPosition={customPosition}
            locationAddressInputValue={locationAddressInputValue}
            locationDescriptionInputValue={locationDescriptionInputValue}
            locationPhoneNumberInputValue={locationPhoneNumberInputValue}
            locationPrefixPhoneNumberInputValue={locationPrefixPhoneNumberInputValue}
            locationStreetNumberInputValue={locationStreetNumberInputValue}
            markerPosition={markerPosition}
            position={position}
            provinceInputValue={provinceInputValue}
            selectedCountry={selectedCountry}
            setCityInputValue={setCityInputValue}
            setCodeInputValue={setCodeInputValue}
            setCountry={setCountry}
            setCustomPosition={setCustomPosition}
            setLocationAddressInputValue={setLocationAddressInputValue}
            setLocationDescriptionInputValue={setLocationDescriptionInputValue}
            setLocationPhoneNumberValue={setLocationPhoneNumberValue}
            setLocationPrefixPhoneNumberInputValue={setLocationPrefixPhoneNumberInputValue}
            setLocationStreetNumberValue={setLocationStreetNumberValue}
            setMarkerPosition={setMarkerPosition}
            setPosition={setPosition}
            setProvinceInputValue={setProvinceInputValue}
            setSelectedCountry={setSelectedCountry}
            setVerifiedLocation={setVerifiedLocation}
            verifiedLocation={verifiedLocation}
            submit={defaultWarehouseSubmit}
        />
    );

    const steps: Array<{ title: string, route: JSX.Element, value: number, optional: false }> = [
        {
            title: "Main information",
            route: companySection,
            value: 0,
            optional: false,
        },
        {
            title: "Default warehouse configuration",
            route: defaultWarehouseSection,
            value: 1,
            optional: false,
        }, {
            title: "Component models configuration",
            route: componentModelSection,
            value: 2,
            optional: false,
        }, {
            title: "Component types configuration",
            route: componentTypesSection,
            value: 3,
            optional: false,
        }, {
            title: "Company Alarm Type",
            route: companyAlarmTypeSection,
            value: 4,
            optional: false,
        },
    ];

    setLegacyBreadcrumb(
        new LegacyPage.Breadcrumb([
            new LegacyPage.BreadcrumbItem("./", "Dashboard", false),
            new LegacyPage.BreadcrumbItem(null, "Web operations", false),
            new LegacyPage.BreadcrumbItem(null, "Create Company", true,
            ),
        ]),
    );

    React.useEffect(() => {
            services.loadingModal();
        }, [
            infoSectionLoading,
            alarmTypeSectionLoading,
            componentTypeSectionLoading,
            componentModelSectionLoading,
            defaultWarehouseLoading,
        ],
    );

    return (
        <div className="w-100 mx-auto" id={"createCompany"}>
            <Card>
                <Card.Body>
                    <ThemeProvider theme={defaultTheme}>
                        <MultiSwitcher
                            points={steps}
                            selectedValue={currentStep}
                            styles={{root: {pointerEvents: "none"}}}
                            label={""}
                        />
                        <div style={{height: "20px"}}/>
                        {steps[currentStep].route}
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </div>
    );
};
