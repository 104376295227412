export const ACCELEROMETER_INTERRUPT_AXIS_MASK_FOR_DOOR_OPENING = "55cef7e7-58ec-419a-b26b-bf9aec062e9d";
export const ACCELEROMETER_INTERRUPT_DURATION_FOR_DOOR_OPENING = "0a3f2e6e-4ae9-4da3-8718-2a8ebbd3b622";
export const ACCELEROMETER_INTERRUPT_HIT_DURATION = "29fa050d-44e1-46f8-a7f8-9ec1be0a00f7";
export const ACCELEROMETER_INTERRUPT_HIT_THRESHOLD = "2e9b60f9-3b85-429a-8a4d-67c19582ecb1";
export const ACCELEROMETER_INTERRUPT_THRESHOLD_FOR_DOOR_OPENING = "6cd815c1-0a8d-4c02-b8c8-030b538302ff";
export const ACCESSIBLE_VIA_REST = "bf88c136-c9e9-11e7-a2a6-525400c5f801";
export const ACTIVE_MOVEMENT_ALARM_ON_WAREHOUSE_AND_FACTORIES = "703fedd2-65f0-4d97-ba66-146dad2cb385";
export const ALARM_BIT_MASK = "cf23e91a-945b-11ea-aa28-82215884149d";
export const ALARM_SMS_NOTIFICATION_DELAY = "bf88be3e-c9e9-11e7-a2a6-525400c5f801";
export const ALARM_SMS_NOTIFICATION_TO_VAN = "bf88b5d8-c9e9-11e7-a2a6-525400c5f801";
export const AMOUNT_FILTERED = "bf88ac32-c9e9-11e7-a2a6-525400c5f801";
export const APN_ADDRESS = "68375ba6-ad03-11e8-b9d8-525400c5f801";
export const APN_PASSWORD = "9c150b8a-ad03-11e8-b9d8-525400c5f801";
export const APN_USER = "8d3033d8-ad03-11e8-b9d8-525400c5f801";
export const BATH_HIGH_TEMPERATURE = "f0d924ae-a2cc-11e7-bbc8-525400c5f801";
export const BILLING_TYPE = "352588f8-84f1-11e8-a839-525400c5f801";
export const BLOCK_EQUIPMENT_SUPPORTED = "91e376c5-9417-4cf6-8771-338826c28969";
export const BOILER_HIGH_PRESSURE_MONITORING_DELTA_TIME = "730244d6-6fde-11e8-913c-525400c5f801";
export const BOILER_LOW_PRESSURE_MONITORING_DELTA_TIME = "f6508646-6fdd-11e8-913c-525400c5f801";
export const BOILER_MAXIMUM_REGULAR_PRESSURE_VALUE = "4017ae16-6fdf-11e8-913c-525400c5f801";
export const BOILER_MINIMUM_REGULAR_PRESSURE_VALUE = "a4bc4c9c-6fde-11e8-913c-525400c5f801";
export const BUSINESS_UNIT_COMPANY_ID = "bf88a638-c9e9-11e7-a2a6-525400c5f801";
export const BUZZER = "f0d92544-a2cc-11e7-bbc8-525400c5f801";
export const C0_CARB_DISPENSE_QUANTITY = "bf89b2e4-c9e9-11e7-a2a6-525400c5f801";
export const C0_NUM_H2O_PULSES = "bf8975a4-c9e9-11e7-a2a6-525400c5f801";
export const C3_CARB_DISPENSE_QUANTITY = "bf8a2e90-c9e9-11e7-a2a6-525400c5f801";
export const C3_CARB_DTEMP = "bf89b62c-c9e9-11e7-a2a6-525400c5f801";
export const C3_DNUM_CONDITION_HAPPENED = "bf89b94c-c9e9-11e7-a2a6-525400c5f801";
export const CAPACITY = "bf88d950-c9e9-11e7-a2a6-525400c5f801";
export const CAPACITY_BY_ITEMS = "0ee2df88-9fdd-44c9-b485-88260a90db8e";
export const CARBONATOR_PUMP_TIMEOUT = "f0d924f4-a2cc-11e7-bbc8-525400c5f801";
export const CHECK_CONFIGURATION_MESSAGE_REQUIRED = "2f87dc84-a7db-4053-adad-196c0da5a987";
export const CLEAN_CONDENSER_TEMPERATURE = "f0d92468-a2cc-11e7-bbc8-525400c5f801";
export const COIL_EXTERNAL_DIAMETER = "fdcbd5e0-31d7-11e8-8029-525400c5f801";
export const COIL_INTERNAL_DIAMETER = "fdcbdafe-31d7-11e8-8029-525400c5f801";
export const COIL_LENGTH = "fdbb45ae-31d7-11e8-8029-525400c5f801";
export const COMMUNICATION_FREQUENCY = "d6c8a18c-ad04-11e8-b9d8-525400c5f801";
export const COMMUNICATION_PROTOCOL = "076a9c60-ad04-11e8-b9d8-525400c5f801";
export const COMMUNICATION_TYPE = "eb8bdc2d-ba67-4b38-b4e1-5a1c93ce84fb";
export const COMPANY_CRITICAL_PRODUCT_STOCK_LEVEL_THRESHOLD_FOR_POSTMIX = "ee1fff47-216f-4564-8a84-0ed0e2eb845d";
export const COMPANY_CRITICAL_PRODUCT_STOCK_LEVEL_THRESHOLD_FOR_PREMIX = "029003ce-b652-4d7b-bc0f-1b61d6db674d";
export const COMPANY_ICE_BANK_TEST_TEMPERATURE_THRESHOLD = "94be67dc-f9df-11e7-8f28-525400c5f801";
export const COMPANY_LOW_PRODUCT_STOCK_LEVEL_THRESHOLD_FOR_POSTMIX = "c01dfb2a-7855-417c-b7f8-19fc6a42f93d";
export const COMPANY_LOW_PRODUCT_STOCK_LEVEL_THRESHOLD_FOR_PREMIX = "f49ee218-86d5-4e4c-8a87-e6e8abb8a7d2";
export const COMPANY_WITH_BLOCK_EQUIPMENT_ENABLED = "169460b7-545b-44f1-a4cd-16039d4bac9e";
export const COMPANY_WITH_CENSUS = "f34c4155-c6f3-4562-a9cc-7f23579bb6d3";
export const COMPANY_WITH_STOCK_MANAGEMENT = "37463451-9947-4adc-86be-9dcf99de2bf0";
export const COMPANY_WITH_THERMOSTAT_READ_AND_WRITE = "69976c8e-8242-4e56-8852-bf790dc36e0a";
export const COMPANY_WITH_WORK_ORDER_DUMMY = "e3d5f914-a8a8-4eeb-af07-266e4e96ff43";
export const COMPATIBLE_CONTROL_BOARD_HW_NAMES = "bf88d068-c9e9-11e7-a2a6-525400c5f801";
export const CONSUMED_ITEMS = "82bf84d6-a0ca-4e5b-b529-8e3ef8489e62";
export const CONSUMED_QUANTITY = "91236460-f9df-11e7-8f28-525400c5f801";
export const CONSUMPTIONS_SENDING_FREQUENCY = "4b106c68-9f22-11e9-bcf6-941882820f48";
export const CONTROLLER_UNIT_OF_MEASURE = "49f2238a-9d8a-4249-8feb-6ab6920ccfc0";
export const CONTROLLER_UNIT_OF_MEASURE_IS_EDITABLE = "42562019-55f4-4196-90a6-c1c891e03f1a";
export const COOLER_ID = "f0d921de-a2cc-11e7-bbc8-525400c5f801";
export const CREATE_DEVICE_MOVEMENT_WARNING = "bb08652c-c378-4f7a-8abf-967031df099c";
export const CREATE_NEW_UNREGISTERED_LOCATION = "c43aa3b3-4964-41d9-905a-9caddcd6a1d8";
export const CRITICAL_ESTIMATED_STOCK_LEVEL_THRESHOLD = "50ecb7fa-855d-466e-9c55-7bd41f870d0b";
export const DAYS_TO_EXPIRATION = "bf88d694-c9e9-11e7-a2a6-525400c5f801";
export const DEFAULT_ALARMS_FILTERS = "110944d5-5024-4755-a293-3f121baee6f7";
export const DEFAULT_STOCK_LEVEL_CAPACITY = "726d62fb-f6bb-40c0-94cd-17c158066135";
export const DEFAULT_WAREHOUSE = "bf88b2d6-c9e9-11e7-a2a6-525400c5f801";
export const DELAY_ON_CONDITIONS_CHECKING = "5abcb676-3cdd-4484-b1c5-2f8144d518c5";
export const DELTA_TIME_TO_CLOSE_ALARM = "8937fd36-0819-46ee-afbe-4c81d6171dde";
export const DELTA_TIME_TO_RAISE_ALARM = "7bb95dee-9275-4337-846d-1e293abdbee2";
export const DEVICE_BATTERY_LEVEL = "3b6dfbef-6fb2-4071-b08a-2220c8f4af4c";
export const DEVICE_BATTERY_VOLTAGE_LEVEL = "2a336719-b585-4d44-b122-c08c2913250c";
export const DEVICE_SLAVE_MODEL_IDENTIFIER = "8ba57cea-87ae-4318-b1db-5f86a417eed0";
export const DIAGNOSTIC_GRANULARITY = "9a3e78ba-ad07-11e8-b9d8-525400c5f801";
export const DIAGNOSTIC_SAMPLING_INTERVAL = "489d5cd1-611b-45a4-8f2b-10d597081bf5";
export const DIAGNOSTIC_STORAGE_TYPE = "25b188ad-d228-4f03-a48e-f4b358e85c73";
export const DISABLE_INACTIVE_USER = "580ab895-8b78-417b-ba4e-c7fe3f16c0d4";
export const DISPLACEMENT_DURATION_THRESHOLD = "6c91123e-e121-11e9-ab76-82215884149d";
export const DOOR_DETECTION_WITH_HALL_SENSOR = "9e63a347-d6a1-4c3c-a1bb-8af8ff377ae9";
export const DOOR_OPENING_SENSOR_ALERT_THRESHOLD_TIME = "8438e541-5451-4b3c-8364-d502c2bd1daa";
export const DOOR_OPENING_STATUS = "d54813ac-9420-4b3b-9b49-84ae26e5ccf5";
export const DOOR_OPENING_SUPPORTED = "4ab6a9e1-76be-4752-a9be-672d8e04d5d6";
export const DOOR_OPEN_THRESHOLD_TIME = "67031a63-8567-4936-9c59-2a6e809eca0d";
export const EMOTIVENDING_CONFIGURATION = "f0d92300-a2cc-11e7-bbc8-525400c5f801";
export const EMOTIVENDING_ID = "f0d9218e-a2cc-11e7-bbc8-525400c5f801";
export const ENABLE_SETTING_CODE_REMOTELY = "6a9cb18e-3201-4892-ab0a-930b191d4c62";
export const END_OF_MOVEMENT_DELAY = "0374006f-d791-4d18-99a4-ad266104e195";
export const EQUIPMENT_BLOCK = "f0d9213e-a2cc-11e7-bbc8-525400c5f801";
export const EQUIPMENT_FIRMWARE_VERSION = "edfc7395-4cab-4b34-a528-ba7f3fa978ea";
export const EQUIPMENT_LOCK_REASON = "182562c1-e0b2-4bd7-bda3-4e91c0578ef9";
export const EQUIPMENT_STATUS = "f83929d0-0282-40fb-8ecc-c7d2cbb83030";
export const EQUIPMENT_STATUS_VALUE = "8928e94c-ad4d-4800-a1ba-379feb787238";
export const EQUIPMENT_UNLOCK_REASON = "6aa5ca76-240b-42ec-b769-bd7fd2fe9b7a";
export const ESPRESSO_GROUPS_NUMBER = "4d3adfc6-18b6-11e8-8504-525400c5f801";
export const EXPORT_SALES_BY_ENTITIES = "84824732-5dc6-4e9b-8276-339bb9885034";
export const FAKE_OPERATOR_NUMERIC_MCC_MNC = "0222d196-41b4-11ea-bbe9-20677c1eab20";
export const FLOWMETER_BAR_DEFAULT_MODEL = "bf88f30e-c9e9-11e7-a2a6-525400c5f801";
export const FLOWMETER_BAR_MAX_SIZE = "bf88f002-c9e9-11e7-a2a6-525400c5f801";
export const FLOWMETER_USED_FOR = "bf88afac-c9e9-11e7-a2a6-525400c5f801";
export const FONTAIN_WITH_STOCK_MANAGEMENT = "b630fcf3-29a9-474e-97eb-a2d29788fe70";
export const FORCE_TCP_USAGE = "12c4f542-12a1-11eb-af6d-a2a09b6e9042";
export const FREQUENCY_READING = "f0d92396-a2cc-11e7-bbc8-525400c5f801";
export const FRIDGE_HIGH_TEMPERATURE = "f0d92422-a2cc-11e7-bbc8-525400c5f801";
export const FRIDGE_TEMPERATURE_BELOW_THRESHOLD = "f0d92580-a2cc-11e7-bbc8-525400c5f801";
export const FRIDGE_TEMPERATURE_UPPER_THRESHOLD = "f0d92346-a2cc-11e7-bbc8-525400c5f801";
export const FTP_DIRECTORY = "bf8a8390-c9e9-11e7-a2a6-525400c5f801";
export const FTP_IP_ADDRESS = "59c116ea-f9e2-11e7-a4ee-9418826ed820";
export const FTP_IP_MODE_ = "585ac6e4-8c4d-41dd-b9d0-a67550c054d2";
export const FTP_PASSWORD = "59c11da2-f9e2-11e7-a4ee-9418826ed820";
export const FTP_USERNAME = "59c11b04-f9e2-11e7-a4ee-9418826ed820";
export const GATEWAY = "6f98d395-b853-42ef-8e1c-b74733a482c7";
export const GPS_DEACTIVATION_DELAY_AFTER_MOVEMENT = "089ac9ce-6b3b-46fd-a720-79488fdabbc3";
export const GPS_SAVING_INTERVAL_WHILE_TRACKING = "4ac2c609-9374-458c-a8a9-546e21c6a36c";
export const GPS_TRACKING_MODE = "de063e6b-d6b5-4f3d-adac-f746459dd99d";
export const GROUP_1_BOILER_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "b146447e-6fe6-11e8-913c-525400c5f801";
export const GROUP_1_BOILER_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "ae33de54-6fe6-11e8-913c-525400c5f801";
export const GROUP_1_BOILER_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "b7a2aa1a-6fe6-11e8-913c-525400c5f801";
export const GROUP_1_BOILER_MINIMUM_REGULAR_TEMPERATURE_VALUE = "b4a64f74-6fe6-11e8-913c-525400c5f801";
export const GROUP_1_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "981e7c3e-6fdf-11e8-913c-525400c5f801";
export const GROUP_1_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "92c5fcd0-6fdf-11e8-913c-525400c5f801";
export const GROUP_1_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "a11e2014-6fdf-11e8-913c-525400c5f801";
export const GROUP_1_MINIMUM_REGULAR_TEMPERATURE_VALUE = "9c0b1528-6fdf-11e8-913c-525400c5f801";
export const GROUP_2_BOILER_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "6c71b3be-6fe7-11e8-913c-525400c5f801";
export const GROUP_2_BOILER_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "682e780a-6fe7-11e8-913c-525400c5f801";
export const GROUP_2_BOILER_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "74a3bcd0-6fe7-11e8-913c-525400c5f801";
export const GROUP_2_BOILER_MINIMUM_REGULAR_TEMPERATURE_VALUE = "7051ab7e-6fe7-11e8-913c-525400c5f801";
export const GROUP_2_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "1d24d010-6fe3-11e8-913c-525400c5f801";
export const GROUP_2_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "199c6b6a-6fe3-11e8-913c-525400c5f801";
export const GROUP_2_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "24c7b38c-6fe3-11e8-913c-525400c5f801";
export const GROUP_2_MINIMUM_REGULAR_TEMPERATURE_VALUE = "20abcb4e-6fe3-11e8-913c-525400c5f801";
export const GROUP_3_BOILER_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "99512982-6fe7-11e8-913c-525400c5f801";
export const GROUP_3_BOILER_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "9562bbec-6fe7-11e8-913c-525400c5f801";
export const GROUP_3_BOILER_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "a1345372-6fe7-11e8-913c-525400c5f801";
export const GROUP_3_BOILER_MINIMUM_REGULAR_TEMPERATURE_VALUE = "9d9504dc-6fe7-11e8-913c-525400c5f801";
export const GROUP_3_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "4cfbd022-6fe3-11e8-913c-525400c5f801";
export const GROUP_3_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "2dc693e0-6fe3-11e8-913c-525400c5f801";
export const GROUP_3_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "5a49964c-6fe3-11e8-913c-525400c5f801";
export const GROUP_3_MINIMUM_REGULAR_TEMPERATURE_VALUE = "513bf180-6fe3-11e8-913c-525400c5f801";
export const GROUP_4_BOILER_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "cc11e8d4-6fe7-11e8-913c-525400c5f801";
export const GROUP_4_BOILER_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "c857824e-6fe7-11e8-913c-525400c5f801";
export const GROUP_4_BOILER_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "c93402fe-7074-11e8-9c8e-9418826ed820";
export const GROUP_4_BOILER_MINIMUM_REGULAR_TEMPERATURE_VALUE = "d21f384e-6fe7-11e8-913c-525400c5f801";
export const GROUP_4_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "91f44a9c-6fe3-11e8-913c-525400c5f801";
export const GROUP_4_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "974b41d0-6fe3-11e8-913c-525400c5f801";
export const GROUP_4_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "8b60df74-6fe3-11e8-913c-525400c5f801";
export const GROUP_4_MINIMUM_REGULAR_TEMPERATURE_VALUE = "8ea108a8-6fe3-11e8-913c-525400c5f801";
export const HIDE_EMPTY_SUB_COMPANY = "a6505760-3470-4344-b883-1cc5985726b4";
export const HIERARCHY_LEVELS_COMPANY_MAPPING = "20457f2a-f4b3-4342-923e-f5fe335efce4";
export const HIGH_TEMPERATURE_ALARM_MODE = "e3e16a57-4a67-4586-94dd-b2465b67d38c";
export const HIGH_TEMPERATURE_BELOW_THRESHOLD = "5157bf42-3822-4326-87e9-9a77dfb21877";
export const HIGH_TEMPERATURE_RECOVERY_DELAY = "6f8e3ab2-37fc-4427-935b-f9e276b21345";
export const HIGH_TEMPERATURE_THRESHOLD = "46cb6ed3-3b80-4b3d-93d8-41b01e39400b";
export const HIGH_TEMPERATURE_THRESHOLD_TIME = "2c1804e1-3ad8-4685-8b8f-4f7fa7989f8d";
export const HIT_NUMBER_THRESHOLD_FOR_MOVEMENT = "b131d228-fa1b-45e4-8be3-68bc36f4a894";
export const ICE_BANK_KILOGRAMNS = "fdcbe274-31d7-11e8-8029-525400c5f801";
export const IMPULSES_DECILITER_RATIO = "bf88d388-c9e9-11e7-a2a6-525400c5f801";
export const IMPULSES_DECILITER_RATIO_POS_0 = "bf88c4f6-c9e9-11e7-a2a6-525400c5f801";
export const IMPULSES_DECILITER_RATIO_POS_1 = "bf88c834-c9e9-11e7-a2a6-525400c5f801";
export const IMPULSES_DECILITER_RATIO_POS_2 = "bf88cd3e-c9e9-11e7-a2a6-525400c5f801";
export const IMPULSES_DECILITER_RATIO_POS_3 = "3488c7f8-e65c-11e8-aca6-525400c5f801";
export const INITIAL_FRIGORIES = "fdcbdd6a-31d7-11e8-8029-525400c5f801";
export const INTERFACE_PROPERTIES_DEFAULT_VALUES = "826ad804-b0a9-4202-89bc-f58cb9f4139c";
export const IP_ADDRESS = "e3126e86-57b5-487b-b36a-9a65e5e93ce6";
export const IP_MODE = "4bbb991d-a49e-42de-b721-d50321e396af";
export const LIFE_EXPECTANCY = "bf88a958-c9e9-11e7-a2a6-525400c5f801";
export const LOCK_UNLOCK_EQUIPMENT = "42edbcb2-945d-11ea-aa28-82215884149d";
export const LOG_ENABLED = "9569b39b-5ad4-4fc0-8b81-8348185c055c";
export const LOG_HIT_INFORMATION = "a4a602df-dfba-4648-a13d-7f7a738f6a61";
export const LOG_LEVEL = "2df65e4c-def6-11eb-829c-a2a09b6e9042";
export const LOWER_THRESHOLD_ALARM = "1e950e7c-f3d1-4ef4-9058-6e1bf8c1fb84";
export const LOW_ESTIMATED_STOCK_LEVEL_THRESHOLD = "8dc167de-fdcc-4d9b-8386-00eec5266113";
export const LOW_TEMPERATURE_ACTIVATION_DELAY = "771ce13b-3d37-45dd-a55b-ee67da7d0879";
export const LOW_TEMPERATURE_ALARM_MODE = "deb65553-8ffd-4cfe-8e97-6aad68317c97";
export const LOW_TEMPERATURE_BELOW_THRESHOLD = "74a116b9-de15-4511-8cfc-c61d12a8f32c";
export const LOW_TEMPERATURE_RECOVERY_DELAY = "3b7dcdcc-3f99-4913-b661-6f0a9e7a85c4";
export const LOW_TEMPERATURE_UPPER_THRESHOLD = "a0b8622b-be21-4638-9c61-ba9c16d9f445";
export const MAINS_MCC = "a865a738-65e8-4bd6-95c0-67eafad26783";
export const MANAGE_ALARM_CODES = "754b85b1-788b-4f39-897a-9dda5c97aac5";
export const MASK = "7c608bde-4a9a-4add-a702-79bdf1bc2186";
export const MAX_FLOWMETERS_NUMBER = "bf88e1e8-c9e9-11e7-a2a6-525400c5f801";
export const MAXIMUM_QUANTITY_POURABLE = "fdcbdfea-31d7-11e8-8029-525400c5f801";
export const MAXIMUM_QUANTITY_POURABLE_AT_16C = "fdcbe49a-31d7-11e8-8029-525400c5f801";
export const MAXIMUM_QUANTITY_POURABLE_AT_24C = "fdcbe72e-31d7-11e8-8029-525400c5f801";
export const MAXIMUM_QUANTITY_POURABLE_AT_32C = "fdcbec9c-31d7-11e8-8029-525400c5f801";
export const MAXIMUM_QUANTITY_POURABLE_AT_43C = "fdcbef1c-31d7-11e8-8029-525400c5f801";
export const MAXIMUM_SET_POINT_TEMPERATURE = "ca1490f8-cbaf-11e8-b16f-525400c5f801";
export const MAX_NUMBER_OF_ALARM_TRANSMISSION_TENTATIVES = "8a2648d5-492e-4d3f-b940-928f8f2b5b7e";
export const MAX_TANKS_NUMBER = "77380f60-f9df-11e7-8f28-525400c5f801";
export const MAX_TIME_BETWEEN_CONSECUTIVE_HIT = "7a2718e3-46bc-4d57-b235-254622b95d60";
export const MINIMUM_DOOR_TIME_FOR_DOOR_COUNT = "06bfffd3-9420-417e-a8ab-1700684cdb6a";
export const MINIMUM_SET_POINT_TEMPERATURE = "e78e70d6-cbaf-11e8-b16f-525400c5f801";
export const MIN_SECONDS_OF_ALARM_RETRANSMISSION_INTERVAL_ = "5bcf627d-6d27-47b8-ac1c-0513b05d525d";
export const MODEM_SCRIPT_APPLICATION = "f0d920c6-a2cc-11e7-bbc8-525400c5f801";
export const MODEM_SCRIPT_VERSION = "f0d91e00-a2cc-11e7-bbc8-525400c5f801";
export const MODEM_SERVER_IP_MODE_ = "8ce6b0ac-2f54-49e2-992f-9197748899e4";
export const NETWORK_SIGNAL_DEBUG_DIAGNOSTICS_FREQUENCY_MS = "b8f97558-eda8-43da-a672-ba4715d98dec";
export const NETWORK_SIGNAL_DEBUG_DIAGNOSTICS_GRANULARITY_MS = "fa9ded33-f513-46b6-903a-81a4d39737d2";
export const NUMBER_GPS_POSITIONS_TRIGGERING_TRANSMISSION = "2654b968-2b17-433a-9211-a52a3338da3f";
export const OCTO_GEO_SIMULATION = "96fafcf4-e469-422b-ae2f-ac806c465089";
export const P1_DTIME_OVER_VOLTAGE = "bf897bf8-c9e9-11e7-a2a6-525400c5f801";
export const P1_OVER_VOLTAGE = "bf8978ec-c9e9-11e7-a2a6-525400c5f801";
export const P2_DTIME_UNDER_VOLTAGE = "bf898224-c9e9-11e7-a2a6-525400c5f801";
export const P2_UNDER_VOLTAGE = "bf897efa-c9e9-11e7-a2a6-525400c5f801";
export const PASSWORD_HASH = "7f078b0b-6164-4514-8166-4097b87e400c";
export const PLUG_STATUS = "bf88e792-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_1_CALIBRATION_VALUE = "8716113e-c26d-49fb-a372-eb9ef7f69ca2";
export const PROBE_1_FAILURE_MONITORING_DELTA_TIME = "078edb54-dede-11e9-a4d5-941882820f48";
export const PROBE_1_FAILURE_WAITING_TIME_BEFORE_START_MONITORING = "1223f81a-dede-11e9-a4d5-941882820f48";
export const PROBE_1_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a3ba6-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_1_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a3822-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_1_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a4254-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_1_MINIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a3f0c-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_2_CALIBRATION_VALUE = "63f8c42f-31c3-4ac3-9296-7b07453f3b47";
export const PROBE_2_FAILURE_MONITORING_DELTA_TIME = "1a17d46a-dede-11e9-a4d5-941882820f48";
export const PROBE_2_FAILURE_WAITING_TIME_BEFORE_START_MONITORING = "215d590c-dede-11e9-a4d5-941882820f48";
export const PROBE_2_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a4d44-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_2_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a4998-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_2_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a53c0-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_2_MINIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a5082-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_3_CALIBRATION_VALUE = "85174a91-acbd-4e1b-85bc-155e5c364f74";
export const PROBE_3_FAILURE_MONITORING_DELTA_TIME = "2bbfd780-dede-11e9-a4d5-941882820f48";
export const PROBE_3_FAILURE_WAITING_TIME_BEFORE_START_MONITORING = "3408a6d8-dede-11e9-a4d5-941882820f48";
export const PROBE_3_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a620c-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_3_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a5b04-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_3_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a6a72-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_3_MINIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a66d0-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_4_CALIBRATION_VALUE = "3d7fa32b-c61f-408e-925a-1c013bccbcae";
export const PROBE_4_FAILURE_MONITORING_DELTA_TIME = "3bf71db6-dede-11e9-a4d5-941882820f48";
export const PROBE_4_FAILURE_WAITING_TIME_BEFORE_START_MONITORING = "43cfc90c-dede-11e9-a4d5-941882820f48";
export const PROBE_4_HIGH_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a75ee-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_4_LOW_TEMPERATURE_MONITORING_DELTA_TIME = "bf8a7256-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_4_MAXIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a7c74-c9e9-11e7-a2a6-525400c5f801";
export const PROBE_4_MINIMUM_REGULAR_TEMPERATURE_VALUE = "bf8a7940-c9e9-11e7-a2a6-525400c5f801";
export const PRODUCT_EXIT_TEMPERATURE = "fdcbf296-31d7-11e8-8029-525400c5f801";
export const PYTHON_LENGTH = "a77fd29c-33fb-11e8-aa5b-525400c5f801";
export const QR_CODE_FOR_EQUIPMENT = "c7054651-4cf6-fb78-446d-9c49ec205801";
export const R0_DTEMP_ON_COMP = "bf88fc00-c9e9-11e7-a2a6-525400c5f801";
export const R0_DTIME_DTEMP_COMP = "bf88feee-c9e9-11e7-a2a6-525400c5f801";
export const R0_DTIME_ON_COMP = "bf88f8fe-c9e9-11e7-a2a6-525400c5f801";
export const R0_START_TEMP_COMP = "bf89de04-c9e9-11e7-a2a6-525400c5f801";
export const R10_DISPENSE_QUANTITY = "bf898ada-c9e9-11e7-a2a6-525400c5f801";
export const R10_DTIME_OVER_TEMP = "bf8985da-c9e9-11e7-a2a6-525400c5f801";
export const R10_START_TEMP_PRODUCT = "bf8a187e-c9e9-11e7-a2a6-525400c5f801";
export const R11_R12_DTIME_OVER_TEMP = "bf898e18-c9e9-11e7-a2a6-525400c5f801";
export const R11_R12_START_TEMP_COMP = "bf8a1bb2-c9e9-11e7-a2a6-525400c5f801";
export const R1_DTIME_TEMP_BATH = "bf890808-c9e9-11e7-a2a6-525400c5f801";
export const R1_DTIME_TEMP_COMP = "bf8901f0-c9e9-11e7-a2a6-525400c5f801";
export const R1_DTIME_TEMP_RECIRC = "bf890506-c9e9-11e7-a2a6-525400c5f801";
export const R1_LIMIT_TEMP_BATH = "bf89ef02-c9e9-11e7-a2a6-525400c5f801";
export const R1_LIMIT_TEMP_COMP = "bf89e12e-c9e9-11e7-a2a6-525400c5f801";
export const R1_LIMIT_TEMP_RECIRC = "bf89eb6a-c9e9-11e7-a2a6-525400c5f801";
export const R1_THR_TEMP_COMP = "bf89e480-c9e9-11e7-a2a6-525400c5f801";
export const R2_DTIME_ON_MACHINE = "bf890b00-c9e9-11e7-a2a6-525400c5f801";
export const R2_DTIME_TEMP_RECIRC = "bf890e2a-c9e9-11e7-a2a6-525400c5f801";
export const R2_LIMIT_TEMP_RECIRC = "bf89f268-c9e9-11e7-a2a6-525400c5f801";
export const R2_LIMIT_TEMP_RECIRC_IDLE_ = "1c3da712-9aea-11e8-a990-525400c5f801";
export const R3_DTIME_TEMP_BATH = "bf8911d6-c9e9-11e7-a2a6-525400c5f801";
export const R3_LIMIT_TEMP_BATH = "bf89f678-c9e9-11e7-a2a6-525400c5f801";
export const R3_THR_TEMP_BATH = "bf89f98e-c9e9-11e7-a2a6-525400c5f801";
export const R4_DTIME_OFF_COMP = "bf8914ba-c9e9-11e7-a2a6-525400c5f801";
export const R4_DTIME_TEMP_BATH = "bf891adc-c9e9-11e7-a2a6-525400c5f801";
export const R4_DTIME_TEMP_RECIRC = "bf8917a8-c9e9-11e7-a2a6-525400c5f801";
export const R4_LIMIT_TEMP_BATH = "bf8a00dc-c9e9-11e7-a2a6-525400c5f801";
export const R4_LIMIT_TEMP_RECIRC = "bf89fd94-c9e9-11e7-a2a6-525400c5f801";
export const R5_CURRENT_INVERTER = "bf893224-c9e9-11e7-a2a6-525400c5f801";
export const R6_LIMIT_TEMP_INVERTER = "bf8a0406-c9e9-11e7-a2a6-525400c5f801";
export const R6_NUM_RETURN = "bf8935e4-c9e9-11e7-a2a6-525400c5f801";
export const R6_RETURN_TEMP_INVERTER = "bf8a0ec4-c9e9-11e7-a2a6-525400c5f801";
export const R6_TIME_RETURN = "bf8939fe-c9e9-11e7-a2a6-525400c5f801";
export const READ_AND_WRITE_REGISTERS_AS_PNU = "ec2417e7-a8ae-42d9-9a50-3cf875e4bd6e";
export const READ_AND_WRITE_SUPPORTED_FW_VERSION = "eb6095b2-c7c1-4598-bba5-c813861e1959";
export const RECIRCLE_HIGH_TEMPERATURE = "f0d923dc-a2cc-11e7-bbc8-525400c5f801";
export const REDUCED_MODEM_OPERATION = "1627400a-41af-11ea-bbe9-20677c1eab20";
export const REGISTERED_MINUTES = "f99f489e-41b1-11ea-bbe9-20677c1eab20";
export const REMOVE_INACTIVE_LOCATION_INFO = "4d0418e0-cc2e-4c91-a57c-6462e54b3dd0";
export const REMOVE_INACTIVE_USER_INFO = "8a28d383-8f45-4af4-973e-12796a9c140d";
export const RTCC_TICK_INTERVAL = "a093f700-8aa9-4e0b-b103-c68a7e748670";
export const SALE_TARGET = "3e5ba4af-99dd-464f-a077-8771b7967368";
export const SECOND_SIM_APN_ADDRESS = "7d61dea0-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_APN_PASSWORD = "7d61d590-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_APN_USER = "7d61da4a-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_FTP_DIRECTORY = "7d61edd2-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_FTP_IP_ADDRESS = "7d61ea1c-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_FTP_PASSWORD = "7d61e260-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_FTP_USERNAME = "7d61e60c-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_SERVER_ADDRESS = "7d61ccc6-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_SIM_SERVER_PORT = "b4e66efe-03e8-11eb-b9af-a2a09b6e9042";
export const SECOND_TYPE_DIAGNOSTICS_GRANULARITY = "531d6ce4-9f22-11e9-bcf6-941882820f48";
export const SECOND_TYPE_DIAGNOSTICS_SENDING_FREQUENCY = "5b2d7a8c-9f22-11e9-bcf6-941882820f48";
export const SERVER_ADDRESS = "bf8a9056-c9e9-11e7-a2a6-525400c5f801";
export const SERVER_PORT = "bf8a968c-c9e9-11e7-a2a6-525400c5f801";
export const SET_POINT_TEMPERATURE = "e3234cd4-e458-440d-a46b-0e1c905ebcb1";
export const SET_POINT_TEMPERATURE_MAX = "5eaa5ecd-f175-4eb4-b28c-bd7b8d3e8c9e";
export const SET_POINT_TEMPERATURE_MIN = "6f19f3d3-8139-4de1-93d7-4674b7936c46";
export const SHOW_DAY_WEEK_DAY_INTERVALS_SALES = "e5f6989d-01ad-454d-ac6d-93e71ca4e67a";
export const SHOW_ESTIMATED_POSITIONS = "af321352-1806-11e9-9c76-b2a6d5960774";
export const SHOW_WEATHER = "bf88f624-c9e9-11e7-a2a6-525400c5f801";
export const SIM_APN_MODE = "f18470aa-0ea7-43fa-8a30-9181a9354fcf";
export const SIM_PIN_ = "52884fca-6a81-4393-b401-8f85e5f26d83";
export const SLAVE_DEVICE_DEFAULT_ADDRESS = "9b039c34-83ab-44b8-aacf-9e99a0388011";
export const SMS_CREDIT = "bf88b8c6-c9e9-11e7-a2a6-525400c5f801";
export const SMS_CREDIT_THRESHOLD = "bf88bb82-c9e9-11e7-a2a6-525400c5f801";
export const SOLO_DOOR_OPEN_THRESHOLD_TIME = "c95e4564-bbe6-441f-b344-e729cfe3459c";
export const SOLO_GEO_SIMULATION = "99073285-875c-441b-8db6-20365341cfc9";
export const SPARKLING_WATER_FLUX = "bf88df18-c9e9-11e7-a2a6-525400c5f801";
export const SPEED = "bf88dc20-c9e9-11e7-a2a6-525400c5f801";
export const SSID = "6cf9fe9c-b036-49ee-8142-b60ce255ee30";
export const STOCK_LEVEL_DELTA = "36397c68-499e-44c7-bc1a-9701cd296ce5";
export const STOCK_LEVEL_FEEDBACK = "315682af-cf23-4351-bc91-817340348757";
export const STOCK_MANAGEMENT_MESSAGE_FREQUENCY = "0b6eea66-09f0-11e9-93c4-9418826ed820";
export const SWITCH_1_HIGH_LOGIC_LEVEL_MONITORING_DELTA_TIME = "7aca7818-b828-11e8-ab15-525400c5f801";
export const SWITCH_1_LOW_LOGIC_LEVEL_MONITORING_DELTA_TIME = "e22eef3a-b827-11e8-ab15-525400c5f801";
export const SWITCH_2_HIGH_LOGIC_LEVEL_MONITORING_DELTA_TIME = "d6d35bd4-b828-11e8-ab15-525400c5f801";
export const SWITCH_2_LOW_LOGIC_LEVEL_MONITORING_DELTA_TIME = "d25bef30-b828-11e8-ab15-525400c5f801";
export const SWITCH_3_HIGH_LOGIC_LEVEL_MONITORING_DELTA_TIME = "f14659ea-b82c-11e8-a34b-525400c5f801";
export const SWITCH_3_LOW_LOGIC_LEVEL_MONITORING_DELTA_TIME = "edb1cfe4-b82c-11e8-a34b-525400c5f801";
export const SWITCH_4_HIGH_LOGIC_LEVEL_MONITORING_DELTA_TIME = "d6952d92-b82c-11e8-a34b-525400c5f801";
export const SWITCH_4_LOW_LOGIC_LEVEL_MONITORING_DELTA_TIME = "dae086c6-b82c-11e8-a34b-525400c5f801";
export const SWITCH_5_HIGH_LOGIC_LEVEL_MONITORING_DELTA_TIME = "b588a69c-b82c-11e8-a34b-525400c5f801";
export const SWITCH_5_LOW_LOGIC_LEVEL_MONITORING_DELTA_TIME = "ba1b04ca-b82c-11e8-a34b-525400c5f801";
export const SWITCH_6_HIGH_LOGIC_LEVEL_MONITORING_DELTA_TIME = "9d9411b6-b82c-11e8-a34b-525400c5f801";
export const SWITCH_6_LOW_LOGIC_LEVEL_MONITORING_DELTA_TIME = "a1b73084-b82c-11e8-a34b-525400c5f801";
export const T0_DTIME_TEMP_AMBIENT = "bf893d0a-c9e9-11e7-a2a6-525400c5f801";
export const T0_DTIME_TEMP_DEVICE = "bf89bc4e-c9e9-11e7-a2a6-525400c5f801";
export const T0_LIMIT_TEMP_AMBIENT = "bf8a1216-c9e9-11e7-a2a6-525400c5f801";
export const T0_START_TEMP_DEVICE = "bf8a1ee6-c9e9-11e7-a2a6-525400c5f801";
export const T0_THR_TEMP_AMBIENT = "bf8a1536-c9e9-11e7-a2a6-525400c5f801";
export const T10_DTIME_TEMP_DEVICE = "bf89bfbe-c9e9-11e7-a2a6-525400c5f801";
export const T11_DISPENSE_QUANTITY = "bf89c748-c9e9-11e7-a2a6-525400c5f801";
export const T12_DTIME_DISPENSE_FLAT_PRODUCT = "bf89ca86-c9e9-11e7-a2a6-525400c5f801";
export const T13_DNUM_CONDITION_HAPPENED = "bf89dac6-c9e9-11e7-a2a6-525400c5f801";
export const T13_DTIME_DISPENSE_FLAT_PRODUCT = "bf89d7b0-c9e9-11e7-a2a6-525400c5f801";
export const T1_DTIME_NO_COMM = "bf894020-c9e9-11e7-a2a6-525400c5f801";
export const T2_LOWER_TIME_ON_PUMP = "bf895b82-c9e9-11e7-a2a6-525400c5f801";
export const T2_NUM_PULSES = "bf895f88-c9e9-11e7-a2a6-525400c5f801";
export const T2_NUM_TIMES = "bf894322-c9e9-11e7-a2a6-525400c5f801";
export const T2_UPPER_TIME_ON_PUMP = "bf895254-c9e9-11e7-a2a6-525400c5f801";
export const T6_DTIME_NO_COMM = "bf8962bc-c9e9-11e7-a2a6-525400c5f801";
export const T8_DNUM_CONDITION_HAPPENED = "bf89d0e4-c9e9-11e7-a2a6-525400c5f801";
export const T8_DTIME_DISPENSE_NOT_CONFIG_PRODUCT = "bf89cd9c-c9e9-11e7-a2a6-525400c5f801";
export const TANK_1_EMPTY_THRESHOLD = "59c122ac-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_HIGH_PRESSURE_MONITORING_DELTA_TIME = "59c0d55e-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_LEVEL = "59c12004-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_LOW_PRESSURE_MONITORING_DELTA_TIME = "59c0b1dc-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_MAXIMUM_REGULAR_PRESSURE_VALUE = "59c0dbc6-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_MINIMUM_REGULAR_PRESSURE_VALUE = "59c0d8f6-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_REFILL_HIGH_PRESSURE_DELTA_TIME = "59c101e6-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_REFILL_HIGH_PRESSURE_THRESHOLD = "59c0ff0c-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_REFILL_MIDDLE_PRESSURE_DELTA_TIME = "59c104b6-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_REFILL_NO_PRESSURE_DELTA_TIME = "59c0fc1e-f9e2-11e7-a4ee-9418826ed820";
export const TANK_1_REFILL_NO_PRESSURE_THRESHOLD = "59c0f91c-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_EMPTY_THRESHOLD = "59c127e8-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_HIGH_PRESSURE_MONITORING_DELTA_TIME = "59c0e472-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_LEVEL = "59c12540-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_LOW_PRESSURE_MONITORING_DELTA_TIME = "59c0e1de-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_MAXIMUM_REGULAR_PRESSURE_VALUE = "59c0ee36-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_MINIMUM_REGULAR_PRESSURE_VALUE = "59c0eb48-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_REFILL_HIGH_PRESSURE_DELTA_TIME = "59c10fec-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_REFILL_HIGH_PRESSURE_THRESHOLD = "59c10d12-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_REFILL_MIDDLE_PRESSURE_DELTA_TIME = "59c1130c-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_REFILL_NO_PRESSURE_DELTA_TIME = "59c10a24-f9e2-11e7-a4ee-9418826ed820";
export const TANK_2_REFILL_NO_PRESSURE_THRESHOLD = "59c10772-f9e2-11e7-a4ee-9418826ed820";
export const TANK_STATUS = "8dcd0a5a-f9df-11e7-8f28-525400c5f801";
export const TELEMETRY_BLOCK = "f0d92274-a2cc-11e7-bbc8-525400c5f801";
export const TELEMETRY_SETUP = "bf88ec92-c9e9-11e7-a2a6-525400c5f801";
export const TELEMETRY_STATUS = "f0d922ba-a2cc-11e7-bbc8-525400c5f801";
export const TEMPERATURE_CORRECTION = "f0d9260c-a2cc-11e7-bbc8-525400c5f801";
export const TEMPERATURE_LOST_PER_PITON_METER = "fdcbf61a-31d7-11e8-8029-525400c5f801";
export const TEMPERATURE_OFFSET = "b79a9d1d-5dc3-4f1d-844e-961dc0927f7e";
export const THIRD_TYPE_DIAGNOSTICS_GRANULARITY = "82ff0cfd-c1a5-4630-9e79-847000cab871";
export const THIRD_TYPE_DIAGNOSTICS_SENDING_FREQUENCY = "f4397b9d-8372-4ca9-b707-c3d888575564";
export const TIME_BEFORE_MONITORING_CALCULATED_ALARMS = "72335e7e-945c-11ea-aa28-82215884149d";
export const TIME_TEMPERATURE_ALARM_DELAY = "f0d925c6-a2cc-11e7-bbc8-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_BOILER_PRESSURE = "53961cf2-6fdf-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_1_BOILER_TEMPERATURE = "bd8e9fb0-6fe6-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_1_TEMPERATURE = "a60aeb0c-6fdf-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_2_BOILER_TEMPERATURE = "791562fa-6fe7-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_2_TEMPERATURE = "29373d0c-6fe3-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_3_BOILER_TEMPERATURE = "a603b15e-6fe7-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_3_TEMPERATURE = "62c6eca2-6fe3-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_4_BOILER_TEMPERATURE = "d79afd76-6fe7-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_GROUP_4_TEMPERATURE = "88aa6b7e-6fe3-11e8-913c-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_PROBE_1 = "bf8a45a6-c9e9-11e7-a2a6-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_PROBE_2 = "bf8a5726-c9e9-11e7-a2a6-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_PROBE_3 = "bf8a6dba-c9e9-11e7-a2a6-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_PROBE_4 = "bf8a7fee-c9e9-11e7-a2a6-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_SWITCH_1 = "9b01dbbc-b828-11e8-ab15-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_SWITCH_2 = "da5f98bc-b828-11e8-ab15-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_SWITCH_3 = "f59f762a-b82c-11e8-a34b-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_SWITCH_4 = "d2445362-b82c-11e8-a34b-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_SWITCH_5 = "b1d6fd50-b82c-11e8-a34b-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_SWITCH_6 = "9927bf2e-b82c-11e8-a34b-525400c5f801";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_TANK_1 = "59c0de96-f9e2-11e7-a4ee-9418826ed820";
export const TIME_TO_WAIT_BEFORE_TO_START_CONTROL_TANK_2 = "59c0f0ca-f9e2-11e7-a4ee-9418826ed820";
export const TOTAL_COFFEE_CONSUMED_QUANTITY = "f00c4f22-e724-11e8-a2f0-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_1_GROUP_1 = "ac085124-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_1_GROUP_2 = "ac085318-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_1_GROUP_3 = "ac08550c-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_1_GROUP_4 = "ac0856ec-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_2_GROUP_1 = "ac08517e-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_2_GROUP_2 = "ac08535e-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_2_GROUP_3 = "ac085552-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_2_GROUP_4 = "ac085732-46ff-11e8-8c1e-525400c5f801";
export const TOTAL_COFFEE_CONSUMPTIONS_BUTTON_3_GROUP_1 = "ac0851c4-46ff-11e8-8c1e-525400c5f801";