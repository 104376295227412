import {PickGuid} from "../../models/types";
import {Property, PropertyConfiguration} from "../../models/property/property";
import {assertOk} from "../../utils/requests";


export async function changePropertyValue<E extends {guid: string}>(
    property: PickGuid<Property>,
    entity: E,
    value: string,
): Promise<PropertyConfiguration> {
    const response = await fetch(
        `/properties/${property.guid}/entity/${entity.guid}/configurations/value`,
        {
            method: "PUT",
            body: value
        }
    );
    assertOk(response);
    return response.json();
}